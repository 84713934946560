/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

import ref1 from "../../../../src/app/assets/images/promo/refferal-banner.jpg";
import ref2 from "../../../../src/app/assets/images/promo/refferal-banner-mob.jpg";
import Meta from "../../Meta/Careers";

const Careers = () => {
  //for handle validation for both mobile and web application
  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };
  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const [selectedJob, setSelectedJob] = useState("");
  const [modalTitle, setModalTitle] = useState("Post apply for");

  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    // Fetch the user's country based on their IP
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // ipapi returns the country code in ISO Alpha-2 format, which react-phone-input-2 expects
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        setCountryCode("in");
        console.error("Error fetching IP information:", error);
        // Optionally set a default or handle the error
      });
  }, []);

  // Handler for dropdown change
  const handleChangeDropdown = (event) => {
    setSelectedJob(event.target.value);
    setModalTitle(`Apply for ${event.target.value}`);
  };

  // Handler for Apply Now button
  const handleApplyNowClick = (jobTitle) => {
    setSelectedJob(jobTitle);
    setModalTitle(`Apply for ${jobTitle}`);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .test(
        "no-first-space",
        "First letter should not be a space",
        (value) => value && !/^ /.test(value)
      )
      .required("Please enter your name")
      .test(
        "only-one-space-between",
        "Only one space allowed between words",
        (value) => value && !/ {2,}/.test(value)
      )
      .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name should not exceed 50 characters long"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .test(
        "no-com.co",
        "Please enter a valid email address",
        (value) => !/\.com\.co$/.test(value)
      )
      .test(
        "no-single-letter-after-com",
        "Please enter a valid email address",
        (value) => !/\.com\.[a-zA-Z]$/.test(value)
      )
      .test(
        "no-spaces",
        "Please enter a valid email address",
        (value) => !/\s/.test(value)
      )
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test(
        "only-one-dot-com",
        "Please enter a valid email address",
        (value) => (value.match(/\.com/g) || []).length <= 1
      )
      .test(
        "no-repeated-domain",
        "Please enter a valid email address",
        (value) => {
          const domainExtensions = [".com", ".net", ".in", ".co"];
          const matches = value.match(/\.\w+/g);
          if (matches) {
            const uniqueExtensions = new Set(
              matches.map((match) => match.toLowerCase())
            );
            return uniqueExtensions.size === matches.length;
          }
          return true;
        }
      )
      .test(
        "laxEmail_new",
        "Please enter a valid email address.",
        function (value) {
          if (value) {
            if (
              value.indexOf("@") > 0 &&
              value.indexOf("@") === value.lastIndexOf("@")
            ) {
              const [localPart, domain] = value.split("@");
              const firstChar = localPart[0];
              const lastChar = localPart.charAt(localPart.length - 1);
              const afterAt = domain[0];

              if (
                firstChar === "." ||
                lastChar === "." ||
                /^[A-Za-z]$/.test(afterAt) === false
              ) {
                return false;
              }

              const domainParts = domain.split(".");
              if (domainParts.length < 2) {
                return false;
              }

              const topLevelDomain = domainParts.pop();
              if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
                return false;
              }

              return domainParts.every((part) => part.length > 1);
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .required("Please enter your email address"),

    phone: Yup.string()
      .required("Please enter phone number")
      .min(9, "Please enter a valid phone number"),
    selectpos: Yup.string().required("Please select"),
    docfile: Yup.mixed()
      .required("Please upload your resume")
      .test(
        "fileType",
        "File must be in .doc, .docx, or .pdf format",
        (value) => {
          if (!value) return false; // File is required
          const allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
          return allowedExtensions.test(value.name);
        }
      )
      .test(
        "fileSize",
        "File size must be less than or equal to 5MB",
        (value) => {
          if (!value) return false; // File is required
          const maxSize = 5 * 1024 * 1024; // 5MB in bytes
          return value.size <= maxSize; // Check file size
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      selectpos: "",
      docfile: null,
      pageLink: window.location.href,
    },
    validationSchema: formValidationSchema,
    validateOnChange: true, // Validate field on every change
    validateOnBlur: true, // Validate field on blur
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("selectpos", values.selectpos);
      formData.append("docfile", values.docfile);
      formData.append("pageLink", values.pageLink);
      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            "6LdRfrYpAAAAAM6i4yl1LJWvbrvxcnIhzqn1XWmB",
            { action: "submit" }
          );

          const response = await axios.post(
            "backend/submit_job.php",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              "g-recaptcha-response": token,
            }
          );

          const responseData = response.data;
          if (responseData === 1) {
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) {
              //alert('hi');
              backdrop.remove();
            }
            navigate("/thankyou"); // Redirect to thank you page
          } else if (responseData === 0) {
            console.error("Error submitting form data");
          }
          setIsSubmitting(false);
        });
      }
    },
  });

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("docfile", file);
  };

  const handleApplyNow = (jobTitle) => {
    setSelectedJob(jobTitle);
    formik.setFieldValue("selectpos", jobTitle);
  };

  useEffect(() => {
    formik.setFieldValue("selectpos", selectedJob);
  }, [selectedJob, formik.setFieldValue]);
  return (
    <div className="careers-page">
      <HeaderDark />
      <Meta />
      <section class="d-flex align-items-center justify-content-end banner-sec section-padding-btm">
        <div class="container custom-cn-width position-relative z-index-1">
          <div class="row align-items-center g-lg-5 g-4 justify-content-end">
            <div class="col-lg-12">
              <div class="careers-image">
                <img
                  src={ref1}
                  alt="team"
                  class="img-fluid d-none d-md-block"
                />
                <img
                  src={ref2}
                  alt="team"
                  class="img-fluid d-block d-md-none"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="openings-sec section-padding pt-0">
        <div class="container custom-cn-width">
          <div class="row g-5 justify-content-center">
            <div class="col-xl-10 col-lg-11">
              <div class="accordion" id="career-accordion">
                {/* <!--Trainee Document Analyst--> Job-ID-09 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-09">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#traineedocAny"
                          aria-expanded="false"
                          aria-controls="comSyssEng">
                          <h2>Trainee Document Analyst</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">BPO</p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              January 16, 2025
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="comSyssEng"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Trainee Document Analyst"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Trainee Document Analyst")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="traineedocAny"
                    class="accordion-collapse collapse job-des job_des_10"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2">Requirements :</h5>
                        <ul class="job-desc">
                          <li>
                            Any Arts or Science Graduates (2024 / 2025 pass
                            outs) preferably Local candidates.
                          </li>
                          <li>Knowledge in MS office and internet is must.</li>
                          <li>
                            Should be good in logical and analytical skills.
                          </li>
                        </ul>
                        <h5 class="mt-3 mb-2">Responsibilities :</h5>
                        <ul class="job-desc">
                          <li>
                            Should accomplish the given tasks on time with
                            accuracy.
                          </li>
                          <li>
                            Respond to data requests by analyzing the documents,
                            data, images, graphs and consolidate the information
                            into a concise report.
                          </li>
                          <li>
                            Follow methodologies, rules to process and
                            understand data presentation requirements based on
                            client/project requirement.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Computer Systems Engineer--> Job-ID-08 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-08">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#comSyssEng"
                          aria-expanded="false"
                          aria-controls="comSyssEng">
                          <h2>Computer Systems Engineer</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software</p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              October 09, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="comSyssEng"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Computer Systems Engineer"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Computer Systems Engineer")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="comSyssEng"
                    class="accordion-collapse collapse job-des job_des_10"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2">Minimum Education :</h5>
                        <ul class="job-desc">
                          <li>
                            Bachelor’s of Science degree in Information
                            Technology or related field.
                          </li>
                        </ul>
                        <h5 class="mt-3 mb-2">Job duties :</h5>
                        <ul class="job-desc">
                          <li>
                            Develop wide range of products and services-based
                            projects using the standard design patterns, and
                            data modeling for the real time & mission critical
                            solutions for Banking, Content Storage Management,
                            ERP, Insurance and Business application.
                          </li>
                          <li>
                            Create and track project roadmaps based on
                            enterprise initiatives.
                          </li>
                          <li>
                            Resource plan and manage by monitoring team members
                            progress and conducting performance review
                            discussions.
                          </li>
                          <li>
                            Collaborate with other departments to address the
                            needs and goals of the organization.
                          </li>
                          <li>
                            Design and implement microservices architecture on
                            AWS cloud, collaborating with cross-functional
                            teams.
                          </li>
                          <li>
                            Define technical architecture, ensuring scalability,
                            reliability, and maintainability.
                          </li>
                          <li>
                            Identify, evaluate, and recommend technology
                            solutions for microservices architecture, meeting
                            business and performance requirements.
                          </li>
                          <li>
                            Work alongside development teams, aligning
                            microservices architecture with development
                            standards and practices.
                          </li>
                          <li>
                            Collaborate with Dev Ops and infrastructure teams
                            for scalable and secure cloud infrastructure design
                            on AWS.
                          </li>
                          <li>
                            Provide technical leadership and mentorship to
                            development teams.
                          </li>
                          <li>
                            Stay abreast of emerging technologies, industry
                            trends, and software development best practices.
                          </li>
                          <li>
                            Maintain a passion for quality, process,
                            programming, and investing, continuously seeking,
                            and sharing improvements.
                          </li>
                          <li>
                            Design and implement large-scale, high-performance
                            software systems using various technologies.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Trainee Web/Graphic Designer--> Job-ID-07 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-7">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#trWebGrpdes"
                          aria-expanded="false"
                          aria-controls="trWebGrpdes">
                          <h2>Trainee Web/Graphic Designer </h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software</p>
                            <p class="me-4">Full-time | Coimbatore</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              September 30, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="job_des_11"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Trainee Web/Graphic Designer"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Trainee Web/Graphic Designer")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="trWebGrpdes"
                    class="accordion-collapse collapse job-des job_des_11"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2">Requirements :</h5>
                        <ul class="job-desc">
                          <li>
                            Any Graduate with basic knowledge in Web/Graphic
                            designing.{" "}
                          </li>
                          <li>
                            Should have good knowledge in Photoshop, Illustrator
                            and Corel Draw.
                          </li>
                          <li>
                            Should have good Knowledge in HTML, JavaScript,
                            JQuery, CSS and Bootstrap.
                          </li>
                          <li>
                            Should have good communication & interpersonal
                            skills.
                          </li>
                          <li>
                            Should be highly creative with excellent design
                            skills & coloring Sense.
                          </li>
                          <li>
                            Should be up-to-date with the latest Web trends,
                            techniques and technologies.
                          </li>
                        </ul>
                        <h5 class="mt-3 mb-2">Responsibilities :</h5>
                        <ul class="job-desc mb-0">
                          <li>
                            Responsible for analyzing websites and creating
                            attractive and corporate designs.
                          </li>
                          <li>
                            Responsible for designing of creative web layouts,
                            web pages and logo.
                          </li>
                          <li>
                            Should be able to visualize the concept of the web
                            site & graphics requirement and able to create
                            design with international standards.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Senior Performance Engineer--> Job-ID-01 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-1">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#srPerfLead"
                          aria-expanded="false"
                          aria-controls="srPerfLead">
                          <h2>Senior Performance Engineer</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software </p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              May 28, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="job_des_1"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Senior Performance Engineer"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Senior Performance Engineer")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="srPerfLead"
                    class="accordion-collapse collapse job-des job_des_1"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2 font-size-22">Job Duties :</h5>
                        <ul class="job-desc">
                          <li>
                            Understanding the business and Identify the
                            Non[1]Functional requirements
                          </li>
                          <li>
                            Identify business critical, most frequently used and
                            resource intensive scenarios based on the PROD IMS
                            logs
                          </li>
                          <li>
                            Understand the scenarios and identify the test data
                            test for the identified scenarios
                          </li>
                          <li>
                            Develops JMETER scripts, add them to master machine
                            and visualize the results in Grafana and draws
                            meaningful conclusion from the results
                          </li>
                          <li>
                            Responsible for co-coordinating with different teams
                            across organization during the test execution and
                            analyzing the test results
                          </li>
                          <li>
                            Monitor the resource utilization from Orion tool
                          </li>
                          <li>
                            Understanding the business and Identify the
                            Non[1]Functional requirements
                          </li>
                          <li>
                            Identify business critical, most frequently used and
                            resource intensive scenarios based on the PROD IMS
                            logs
                          </li>
                          <li>
                            Understand the scenarios and Identify the test data
                            test for the identified scenarios
                          </li>
                          <li>
                            Identify the list of batch jobs for the business
                            from the legacy application
                          </li>
                          <li>
                            Run the batch jobs from the Automatic scheduler and
                            Identify the slow running queries and provide
                            necessary recommendations
                          </li>
                          <li>
                            Design and develop the JMeter scripts for the
                            screens that are in scope
                          </li>
                          <li>
                            Responsible for co-coordinating with different teams
                            across organization during the test execution and
                            analyzing the test results
                          </li>
                          <li>
                            Responsible for LoadRunner Tool Installation and
                            Configuration
                          </li>
                          <li>
                            Responsible for creating Test Strategy, Test Plan
                            and Timeline for Performance Testing
                          </li>
                          <li>Responsible for Requirement Gathering</li>
                          <li>
                            Responsible for creating scripts using Web
                            (HTTP/HTML) protocol
                          </li>
                          <li>Developing the JMeter scripts</li>
                          <li>Validate the APIs with Postman</li>
                          <li>
                            Responsible for co-coordinating with different teams
                            across organization during the test execution and
                            analyzing the test results
                          </li>
                          <li>
                            Responsible for creating defects and managed defect
                            like cycle
                          </li>
                          <li>
                            Responsible for monitoring the system during the
                            execution and identifying the bottlenecks by using
                            SAP T-codes (ST12, ST22, SM66, ST06, ST03, etc.)
                          </li>
                          <li>
                            Responsible for co-coordinating and monitoring the
                            SAP systems during Backend/Interface Testing
                          </li>
                          <li>
                            Responsible for creating defects and managed defect
                            like cycle
                          </li>
                          <li>
                            Responsible for creating Performance Report and
                            presenting the results to Business team
                          </li>
                          <li>
                            Responsible for tracking the Performance changes and
                            co-coordinating with deployment team to have changes
                            implemented in the Production system
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Data Engineer/Python Developer--> Job-ID-02 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-2">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#dataEngPyDev"
                          aria-expanded="false"
                          aria-controls="dataEngPyDev">
                          <h2>Data Engineer/Python Developer</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software </p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              May 10, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="job_des_1"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Data Engineer/Python Developer"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Data Engineer/Python Developer")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="dataEngPyDev"
                    class="accordion-collapse collapse job-des job_des_1"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2 font-size-22">Job Duties :</h5>
                        <ul class="job-desc ps-4">
                          <li>
                            Experience in Data Processing, expertise in
                            developing Queries.
                          </li>
                          <li>
                            Good Knowledge in various phases of SDLC,
                            Requirement Analysis, Design, Development and
                            Testing on various developments and enhancement
                            projects.
                          </li>
                          <li>
                            Working experience in globally diverse team,
                            coordinating with team members working in different
                            time zones and lead to many successes.
                          </li>
                          <li>
                            Have flexibility and ability to learn and use new
                            technologies and to work in team environment as well
                            as independently to get things done. Developing and
                            maintenance of the tool that abstracts the
                            information from the user.
                          </li>
                          <li>
                            Parsers written in Python for extracting useful data
                            from the design database.
                          </li>
                          <li>
                            Development of XMLs for several components which
                            maintains data for several registers.
                          </li>
                          <li>
                            Development of data structures, xml parsing using
                            Python.
                          </li>
                          <li>
                            Usage of advance features like pickle / unpickle in
                            Python for sharing the information across the
                            applications.
                          </li>
                          <li>
                            Defining the components and subcomponents using
                            Python and developed set of library functions over
                            system based on user needs.
                          </li>
                          <li>
                            Development of several Python API’s and harassers
                            that’s works both in Linus and Windows and
                            maintaining them using revision control SVN.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Computer System Engineer/Architect--> Job-ID-03 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-3">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#comSysEngAr"
                          aria-expanded="false"
                          aria-controls="comSysEngAr">
                          <h2>Computer System Engineer/Architect</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software </p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              May 10, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="job_des_1"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Computer System Engineer/Architect"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick(
                            "Computer System Engineer/Architect"
                          )
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="comSysEngAr"
                    class="accordion-collapse collapse job-des job_des_1"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2">Minimum Education :</h5>
                        <ul class="job-desc">
                          <li>
                            Bachelor’s of Science degree in Information
                            Technology or related field.
                          </li>
                        </ul>
                        <h5 class="mt-3 mb-2 font-size-22">Job Duties :</h5>
                        <ul class="job-desc ps-4">
                          <li>
                            Developed wide range of products and services-based
                            projects using the standard design patterns, and
                            data modeling for the real time & mission critical
                            solutions for Banking, Content Storage Management,
                            ERP, Insurance and Business application.
                          </li>
                          <li>
                            Achieved significant reduction in Database Server
                            calls to improvement over all application
                            performance providing significant alert and report
                            latency reductions to the client.
                          </li>
                          <li>
                            Optimized resource productivity by identifying
                            automation opportunities and implementing them.
                          </li>
                          <li>
                            Delegating tasks, mentoring resources, monitoring
                            and reviewing deliverables and tracking project
                            progress to closure.
                          </li>
                          <li>
                            Conducting review meetings on a periodic basis with
                            key stakeholders and project teams.
                          </li>
                          <li>
                            Creating and tracking project roadmaps based on
                            enterprise initiatives.
                          </li>
                          <li>
                            Resource planning and management by monitoring team
                            members progress and conducting performance review
                            discussions.
                          </li>
                          <li>
                            Extensive experience in designing and developing
                            applications using technologies such as Core Java,
                            J2EE, Spring Framework’s & utilities, design
                            patterns, EJB, JMS (MQ Series), Kafka, JDBC, JNDI,
                            XML, Web Services (SOAP and REST), AWS, and ORM
                            tools like JPA & Hibernate.
                          </li>
                          <li>
                            Proficient in-Service Oriented Architecture (SOA):
                            Web Services using SOAP, WSDL, UDDI and Restful Web
                            services using Spring MVC Framework.
                          </li>
                          <li>
                            Design and Architecture: Translating business
                            requirements into technical design using Object
                            Oriented Analysis and Documentation of technical
                            design using UML models and tools such as Rational
                            Rose.
                          </li>
                          <li>
                            Analyzing and understanding business requirements to
                            develop technical specifications.
                          </li>
                          <li>
                            Experience in using build/deploy tools such as
                            Bamboo, and OpenShift for Continuous Integration &
                            Deployment for Microservices.
                          </li>
                          <li>
                            Adept at architecting and deploying solutions on AWS
                            (EC2, Lambda, ECS, ECR, CloudWatch and EKS) Skilled
                            in containerization with Docker and orchestration
                            with Kubernetes.
                          </li>
                          <li>
                            Proficient at designing and developing ESP jobs
                            using spring batch framework.
                          </li>
                          <li>
                            Experience with Databases that involve writing SQL
                            Queries, Views, Triggers, Stored Procedures, and
                            functions.
                          </li>
                          <li>
                            Good experience in Spring MVC, Spring AOP, Spring
                            Boot.
                          </li>
                          <li>
                            Experience in developing web applications using
                            Angular Js, Node Js and Ext Js.
                          </li>
                          <li>
                            Hands-on experience in restructuring the build
                            environment using Gradle, Maven and Groovy.
                          </li>
                          <li>
                            Experience in performance tuning of Loan products on
                            WebSphere and Database.
                          </li>
                          <li>
                            Experience with Use-Case design, Class and Sequence
                            diagrams using UML.
                          </li>
                          <li>
                            Proficient in troubleshooting and resolving
                            intricate technical issues within Java applications.
                          </li>
                          <li>
                            Skilled in documenting technical designs,
                            architecture, and implementation details.
                          </li>
                          <li>
                            Providing technical guidance and support during all
                            phases of the software development lifecycle.
                          </li>
                          <li>
                            Significant expertise in creating task estimates,
                            ETAs, and developing comprehensive work breakdown
                            structures.
                          </li>
                          <li>
                            Demonstrated proficiency in communication, team
                            leadership, and exceptional critical thinking
                            abilities.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Computer System Engineer--> Job-ID-04 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-4">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#comSysEng"
                          aria-expanded="false"
                          aria-controls="comSysEng">
                          <h2>Computer System Engineer</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software </p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              May 10, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="job_des_1"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Computer System Engineer"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Computer System Engineer")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="comSysEng"
                    class="accordion-collapse collapse job-des job_des_1"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2">Minimum Education :</h5>
                        <ul class="job-desc">
                          <li>
                            Bachelor’s of Science degree in Information
                            Technology or related field.
                          </li>
                        </ul>
                        <h5 class="mt-3 mb-2 font-size-22">Job Duties :</h5>
                        <ul class="job-desc ps-4">
                          <li>
                            Expertise in developing responsive and interactive
                            web applications using Angular 8+ framework,
                            leveraging components, services, and routing for
                            seamless user experiences.
                          </li>
                          <li>
                            ASP.Net MVC: Strong background in designing and
                            implementing scalable and maintainable web
                            applications using ASP.Net MVC, adhering to best
                            practices and leveraging Razor syntax for clean UI
                            development.
                          </li>
                          <li>
                            Proficient in utilizing C# programming language to
                            develop robust and efficient software solutions,
                            implementing business logic, data access layers, and
                            unit testing.
                          </li>
                          <li>
                            WPF: Skilled in developing Windows desktop
                            applications using WPF (Windows Presentation
                            Foundation), employing XAML for declarative UI
                            design and following the MVVM pattern for separation
                            of concerns.
                          </li>
                          <li>
                            Web API: Proficient in designing and developing
                            RESTful APIs using ASP.Net Web API, enabling
                            seamless integration with other systems and
                            platforms, and facilitating data exchange using JSON
                            or XML formats.
                          </li>
                          <li>
                            {" "}
                            Experienced in implementing service-oriented
                            architectures using WCF, ensuring secure
                            communication and interoperability between
                            distributed systems.
                          </li>
                          <li>
                            Proficient in VB.Net programming language, with
                            experience in developing Windows applications, web
                            forms, and services.
                          </li>
                          <li>
                            HTML5: Skilled in utilizing HTML5 and CSS3 to create
                            modern and responsive user interfaces, incorporating
                            multimedia elements, semantic markup, and ensuring
                            cross-browser compatibility.
                          </li>
                          <li>
                            Experienced in implementing data access layers using
                            ADO.NET, utilizing data adapters, datasets, and LINQ
                            to interact with databases efficiently.
                          </li>
                          <li>
                            Knowledgeable in utilizing Entity Framework as an
                            ORM tool, simplifying database operations and schema
                            management.
                          </li>
                          <li>
                            Experienced in developing rich internet applications
                            using Silverlight, leveraging XAML and multimedia
                            capabilities.
                          </li>
                          <li>
                            Experience in developing real-time applications
                            using WebSocket API, facilitating seamless
                            bidirectional communication between clients and
                            servers over a persistent connection, enabling
                            efficient and interactive web experiences.
                          </li>
                          <li>
                            Proficient in working with a range of RDBMS
                            including MS SQL Server 2014, Oracle 10g, and MS
                            Access.
                          </li>
                          <li>
                            Experience in developing and generating reports
                            using SQL Reporting Services, Crystal Reports 9 and
                            11, and RDLC, ensuring accurate data presentation
                            and insightful analysis.
                          </li>
                          <li>
                            Strong expertise in full-stack development,
                            including front-end and back-end development,
                            database design, and system architecture.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Informatica  Developer--> Job-ID-05 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-5">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#infoDev"
                          aria-expanded="false"
                          aria-controls="infoDev">
                          <h2>Informatica Developer</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software </p>
                            <p class="me-4">Full-time</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              May 10, 2024
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="job_des_1"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Informatica  Developer"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Informatica  Developer")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="infoDev"
                    class="accordion-collapse collapse job-des job_des_1"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2 font-size-22">Job Duties :</h5>
                        <ul class="job-desc ps-4">
                          <li>
                            Excellent Informatica power centre, Informatica IDQ,
                            SSIS and Amazon Redshift , Oracle (SQL, plsql),
                            Linux, TERADATA development skills.
                          </li>
                          <li>
                            Having hands on experience in REPOSITORY MANAGER,
                            DESIGNER, WORKFLOW MANAGER and WORKFLOW MONITOR.
                          </li>
                          <li>Worked in slowly changing dimension tables.</li>
                          <li>
                            Proficient in ETL and DATAWAREHOUSE concepts used in
                            BI.
                          </li>
                          <li>
                            Extensively worked on Data Analysis, Design,
                            Development, Implementation and Testing of ETL
                            mappings.
                          </li>
                          <li>
                            Worked on the development projects on Informatica in
                            Oracle 9.
                          </li>
                          <li>
                            Well experienced in extraction, transformation and
                            loading of data from heterogeneous sources system
                            like flat file, xml, Oracle.
                          </li>
                          <li>
                            Having hands on experience in Informatica Designer
                            component – Source Analyzer, Transformation
                            Developer, Mapping and Mapplet Designer and strong
                            experience on Workflow manager tool – Task
                            Developer, Workflow and Worklet Designer.
                          </li>
                          <li>
                            Worked on different types of transformations like
                            Source Qualifier, expressions, router, filter,
                            sorter, aggregator, update strategy, lookup,
                            normalize, sequence generator, java Transformation.
                          </li>
                          <li>
                            Have clear understanding of Business Intelligence
                            and Data Warehousing concepts with emphasis on ETL
                            and Life Cycle Development including development,
                            testing and implementation.
                          </li>
                          <li>
                            Experience in Data Warehouse development working
                            with Extraction Transformation and loading using
                            Informatica Power Center with Oracle, Flat file.
                          </li>
                          <li>
                            Responsible for interacting with business partners
                            to identify information needs and business
                            requirements for reports.
                          </li>
                          <li>
                            Knowledge in star and snowflake schemas, dimensional
                            data modeling, Fact and dimensional tables, slowly
                            changing dimensions.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Control-M Technical Lead--> Job-ID-06 */}
                <div class="accordion-item b-r-6">
                  <div class="row g-3 align-items-center">
                    <div class="col-lg-9">
                      <div class="accordion-header" id="job-6">
                        <div
                          class="accordion-button collapsed career-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#conTechLead"
                          aria-expanded="false"
                          aria-controls="conTechLead">
                          <h2>Control-M Technical Lead</h2>
                          <div class="d-lg-flex mt-2">
                            <p class="me-4">Software</p>
                            <p class="me-4">Full-time | Coimbatore</p>
                            <p>
                              <span class="fw-500">Posted Date:</span>
                              October 09, 2023
                            </p>
                          </div>
                          <a
                            class="d-inline-block underline-border mt-1 learn-more"
                            data_id="conTechLead"
                            href="javascript:;">
                            <span class="border-line d-inline-block me-1">
                              Learn more
                            </span>
                            <i class="fa fa-arrow-right fa-sm position-relative"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 text-lg-end">
                      <a
                        href="javascript:;"
                        title="Control-M Technical Lead"
                        class="btn cust-btn-primary secondary-main"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleApplyNowClick("Control-M Technical Lead")
                        }>
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div
                    id="conTechLead"
                    class="accordion-collapse collapse job-des job_des_1"
                    aria-labelledby="job-senior-perf">
                    <div class="accordion-body">
                      <div class="accordion-item-bd">
                        <h5 class="mt-3 mb-2">Requirements :</h5>
                        <ul class="job-desc">
                          <li>
                            Amiable Technical Lead, ready to rapidly onboard new
                            projects.{" "}
                          </li>
                          <li>
                            Achieve rapid and exceptional completion metrics
                            through careful utilization of available skills and
                            technical assets.
                          </li>
                          <li>
                            Assembles effective teams by exploiting
                            complementary competencies and collaborative
                            employee relationships.
                          </li>
                        </ul>
                        <h5 class="mt-3 mb-2">Responsibilities :</h5>
                        <ul class="job-desc mb-0">
                          <li>
                            Providing solutions to critical business batches.
                          </li>
                          <li>
                            Control-M V8 to V9.0.00 and to 9.0.19 upgrade
                            activities.
                          </li>
                          <li>
                            Troubleshooting issues in all the Control-M
                            environments.
                          </li>
                          <li>
                            Creation of custom job types for multiple client
                            applications.
                          </li>
                          <li>
                            Migration of Control-M servers and EM servers.
                          </li>
                          <li>Migration and upgrade of Control-M databases.</li>
                          <li>
                            Creation of SQL, BAT and Shell scripts for
                            automation.
                          </li>
                          <li>Managing complex Control-M variables.</li>
                          <li>
                            Installation of Control-M Agents, Plug-in, Client,
                            Control-M server and EM.
                          </li>
                          <li>
                            Managing Control-M servers in different
                            environments.
                          </li>
                          <li>
                            Creating reports, Web Services, OS, Informatica, and
                            Database jobs for the application teams.
                          </li>
                          <li>
                            Performing year-end activities like calendar
                            migration.
                          </li>
                          <li>
                            Coordinate with all teams during development and QA
                            to gather environment requirements to move into
                            production.{" "}
                          </li>
                          <li>
                            Documentation of processes and procedures for
                            production control and application support and
                            development teams.{" "}
                          </li>
                          <li>
                            Handling high and critical incidents, change
                            requests and providing resolutions.
                          </li>
                          <li>
                            Providing daily and monthly reports for clients.
                          </li>
                          <li>
                            Mentored junior team members, providing educational
                            expertise on technical concepts and Batch
                            Automation.
                          </li>
                          <li>
                            Investigated and addressed system issues to enhance
                            usability and improve functionality.
                          </li>
                          <li>
                            Take ownership during issues and driving them to
                            closure.
                          </li>
                          <li>
                            Represent team at technical, service meetings and
                            take responsibility for actions.
                          </li>
                          <li>
                            Ensure compliance to all documented processes and
                            procedures.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade pr-0"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="apply-now">
                {modalTitle}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form onSubmit={formik.handleSubmit}>
                {/* Name field */}
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Full Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onInput={handleNameInput}
                    placeholder="Name"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>

                {/* Email field */}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onKeyDown={handleKeyPress}
                    placeholder="Email"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>

                {/* Phone field */}
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <PhoneInput
                    country={countryCode}
                    placeholder="Phone"
                    value={formik.values.phone}
                    onChange={(phone) => formik.setFieldValue("phone", phone)}
                    containerStyle={{ marginTop: "0.5rem" }}
                    inputStyle={{
                      width: "100%",
                      height: "38px",
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger">{formik.errors.phone}</div>
                  ) : null}
                </div>

                {/* Company field */}
                <div className="mb-3">
                  <label htmlFor="company" className="form-label">
                    Position applying for <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select valid"
                    id="selectpos"
                    name="selectpos"
                    //value={formik.values.selectpos}
                    //onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-label="Default select example"
                    value={selectedJob}
                    onChange={handleChangeDropdown}>
                    <option
                      value=""
                      class="fs-15 text-mute"
                      disabled="disabled">
                      Current Openings
                    </option>
                    <option value="Senior Performance Engineer">
                      Senior Performance Engineer
                    </option>
                    <option value="Data Engineer/Python Developer">
                      Data Engineer/Python Developer
                    </option>
                    <option value="Computer System Engineer/Architect">
                      Computer System Engineer/Architect
                    </option>
                    <option value="Computer System Engineer">
                      Computer System Engineer
                    </option>
                    <option value="Informatica  Developer">
                      Informatica Developer
                    </option>
                    <option value="Control-M Technical Lead">
                      Control-M Technical Lead
                    </option>
                    <option value="Trainee Web/Graphic Designer">
                      Trainee Web/Graphic Designer
                    </option>
                    <option value="Computer Systems Engineer">
                      Computer Systems Engineer
                    </option>
                    <option value="Trainee Document Analyst">
                      Trainee Document Analyst
                    </option>
                  </select>
                  {formik.touched.selectpos && formik.errors.selectpos ? (
                    <div className="text-danger">{formik.errors.selectpos}</div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Upload your resume(doc, docx, pdf)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    accept=".doc,.docx,.pdf"
                    className="form-control"
                    id="docfile"
                    name="docfile"
                    onChange={handleFileChange}
                  />

                  {formik.touched.docfile && formik.errors.docfile ? (
                    <div className="text-danger">{formik.errors.docfile}</div>
                  ) : null}
                </div>

                <button
                  type="submit"
                  className="btn cust-btn-primary secondary-main mt-3"
                  disabled={isSubmitting}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </div>
  );
};

export default Careers;
